// third-party libraries

// own libraries/files
import { UserAuthType } from '../types/userAuth.type';

const API = `${process.env.REACT_APP_API}`;
const ENV = `${process.env.REACT_APP_ENV}`;
const STORE_VAR = `jwtMuucontrol-${ENV}`;

class AuthService {
  /**
   *
   * @param data = {
   *  token:"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjNhZDliZDIyNDcyYzAxNWRmZDE1NjJmNDgwM2U3NTIxOGJjMzBmOWQ0MjM5MzBiYWQ",
   *   roles:["TYPE_USER_BASIC","TYPE_SUPER_ADMIN"],
   *   permissions:["DummyCreate","DummyUpdate"],
   *   dataUserAuth:{firstName:"Administrator",lastName:"Goheavy",email:"admin@goheavy.com",avatar:{publicUrl:null}},
   *   expiresIn:1296000,
   *   mainRole:"TYPE_SUPER_ADMIN"
   * };
   */
  loginBrowser(data) {
    const { token } = data;
    if (token) {
      localStorage.setItem(STORE_VAR, JSON.stringify(data));
    }
  }

  setStorageBreadCrumb(data) {
    localStorage.setItem(`${STORE_VAR}-bc`, JSON.stringify(data));
  }

  getBreadCrumbData() {
    const breadCrumbData: any = localStorage.getItem(`${STORE_VAR}-bc`) ?? undefined;
    return (breadCrumbData && JSON.parse(breadCrumbData)) || undefined;
  }

  /**
   * Remove data from localStorage
   */
  logout() {
    localStorage.removeItem(`${STORE_VAR}-bc`);
    localStorage.removeItem(STORE_VAR);
  }

  /**
   * @returns true or false
   */
  hasDataStorage() {
    const jwtData: any = localStorage.getItem(STORE_VAR);
    return !!jwtData;
  }

  /**
   * @deprecated should be use getDataStorage()
   * @description return data storage
   */
  getToken() {
    const jwtData: any = localStorage.getItem(STORE_VAR) ? localStorage.getItem(STORE_VAR) : undefined;
    return jwtData;
  }

  /**
   * @returns data Storage
   */
  getSessionData() {
    const jwtData: any = localStorage.getItem(STORE_VAR);
    if (jwtData) {
      return JSON.parse(jwtData);
    }
    return { token: '', roles: [], permissions: [] };
  }

  /**
   * @description Redirect to login
   */
  redirectToLogin() {
    window.location.assign('/login');
  }

  async login(dataInput: any) {
    try {
      const dataLogin: any = await this.loginFetch(dataInput);

      if (dataLogin) {
        return dataLogin;
      }
    } catch (e) {
      throw e;
    }
  }

  async loginFetch(data: any) {
    try {
      const sendData = {
        email: data.email,
        password: data.password
      };

      const response = await fetch(`${API}/auth/token/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(sendData) // body data type must match "Content-Type" header
      });
      if ((response as any).status === 500) {
        throw new Error('500');
      }
      if ((response as any).statusText === 'Unauthorized') {
        throw new Error('403');
      }
      if ((response as any).statusText === 'Bad Request') {
        throw new Error('400');
      }
      const res = await response.json();

      const dataUser: UserAuthType = {
        token: res.authToken,
        firstName: res.firstName,
        lastName: res.lastName,
        userRole: res.userRole,
        userId: res.userId
      };
      return dataUser;
    } catch (e) {
      throw e;
    }
  }
}

const authService = new AuthService();
export default authService;
