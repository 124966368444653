// third-party libraries
import { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
// own libraries/files
import apirestService from './apirest.service';

export abstract class BaseService {
  httpAxios: AxiosInstance;

  protected constructor(url: string, cnf?: AxiosRequestConfig) {
    this.httpAxios = apirestService.createHttpAxios(url, cnf);
  }

  create(item: any): AxiosPromise {
    return this.httpAxios
      .post('', item)
      .then(response => response)
      .catch(err => {
        throw err;
      });
  }

  createForImages(item: any, config?: AxiosRequestConfig): AxiosPromise {
    return this.httpAxios
      .post('', item, config)
      .then(response => response)
      .catch(err => {
        throw err;
      });
  }

  update(item: any): AxiosPromise {
    const itemId = item.id || item.get('id');
    return this.httpAxios
      .put(itemId + '/', item)
      .then(response => response)
      .catch(err => {
        throw err;
      });
  }

  updatePatch(item: any): AxiosPromise {
    const itemId = item.id || item.get('id');
    return this.httpAxios
      .patch(itemId + '/', item)
      .then(response => response)
      .catch(err => {
        throw err;
      });
  }

  updatePatchForImages(item: any, config?: AxiosRequestConfig): AxiosPromise {
    const itemId = item.id || item.get('id');
    return this.httpAxios
      .patch(itemId + '/', item, config)
      .then(response => response)
      .catch(err => {
        throw err;
      });
  }

  public delete(itemId: string | number): AxiosPromise<void> {
    return this.httpAxios
      .delete(itemId + '/')
      .then(response => response)
      .catch(err => {
        throw err;
      });
  }

  async getAll(url: string, params: any) {
    try {
      return await this.httpAxios.get(url, { params });
    } catch (error) {
      throw error;
    }
  }

  public getById(itemId: string | number): AxiosPromise {
    return this.httpAxios
      .get(itemId + '/')
      .then(response => response)
      .catch(err => {
        throw err;
      });
  }
}
