// third-party libraries

// own libraries/files
import {BaseService} from './base.service';

class UserService extends BaseService {
  constructor() {
    super('/users/');
  }
}

const userService = new UserService();
export default userService;
