import { action, computed, observable } from 'mobx';
import { IRouter } from 'rift-router';
import userService from '../services/user.service';

export class AppStore {
  //#region ATTRIBUTES
  @observable isLoading = false;
  @observable private _isOpenMainSideBar = false;
  @observable isEditing = false;
  @observable language = 'es_ES';
  @observable itemMenuSelected = 1;
  @observable itemParentMenuSelected = 0;
  @observable hasMore = true;
  @observable userAuthData = {} as any;
  @observable private _userAuthDataFull = {} as any;
  @observable breadCrumbRoutes = [] as any[];
  @observable breadCrumbData = [] as any[];
  @observable resolutionWidth = 0;
  @observable _router: IRouter = {} as IRouter;
  @observable _newVersionAvailable = false;
  @observable _waitingWorker = null as ServiceWorker | null;
  //#endregion

  //#region ENCAPSULATES
  get router(): IRouter {
    return this._router;
  }

  get isOpenMainSideBar(): boolean {
    return this._isOpenMainSideBar;
  }
  set isOpenMainSideBar(value: boolean) {
    this._isOpenMainSideBar = value;
  }

  get newVersionAvailable() {
    return this._newVersionAvailable;
  }

  set newVersionAvailable(newVersionAvailable: boolean) {
    this._newVersionAvailable = newVersionAvailable;
  }

  get waitingWorker(): ServiceWorker | null {
    return this._waitingWorker;
  }
  set waitingWorker(waitingWorker: ServiceWorker | null) {
    this._waitingWorker = waitingWorker;
  }

  //#endregion

  //#region ACTIONS

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @action
  setIsEditing(isEditing: boolean) {
    this.isEditing = isEditing;
  }

  @action
  setLanguage(language: string) {
    this.language = language;
  }

  @action
  setItemMenuSelected(itemMenuSelected: number) {
    this.itemMenuSelected = itemMenuSelected;
  }

  @action
  setItemParentMenuSelected(itemParentMenuSelected: number) {
    this.itemParentMenuSelected = itemParentMenuSelected;
  }

  @action
  setHasMore(hasMore: boolean) {
    this.hasMore = hasMore;
  }

  @action
  setUserAuthData(userAuthData: any) {
    this.userAuthData = userAuthData;
    setTimeout(() => this.validateAuthUser(userAuthData.userId), 0);
  }

  @action
  setBreadCrumbRoutes(breadCrumbRoutes: any[]) {
    this.breadCrumbRoutes = breadCrumbRoutes;
  }

  @action
  setBreadCrumbData(breadCrumbData: any[]) {
    this.breadCrumbData = breadCrumbData;
  }

  @action
  setResolutionWidth(resolutionWidth: number) {
    this.resolutionWidth = resolutionWidth;
  }

  @action
  private setFullUser(user: any) {
    this._userAuthDataFull = user;
  }

  //#endregion

  //#region COMPUTED
  @computed
  get fullUser(): any {
    return this._userAuthDataFull;
  }
  //#endregion

  //#region METHODS
  updateAuthUserData = (obj: any) => this.setFullUser({ ...this.fullUser, ...obj });

  validateAuthUser = async (userId: number, force = false) => {
    if (!this._userAuthDataFull?.id || force) {
      try {
        const user = await userService.getById(userId);
        this._userAuthDataFull = user.data;
      } catch (e) {
        throw new Error((e as Error).message);
      }
    }
  };
  //#endregion

  //#region EVENTS
  onCloseMainSideBar = () => (this.isOpenMainSideBar = false);
  onOpenMainSideBar = () => (this.isOpenMainSideBar = true);
  //#endregion

  /**
   *
   * @param value : resolution number
   * @description Return true if resolution is equal or minor than `value`
   */
  isResolutionOrMinor = (value: number) => (this.resolutionWidth && this.resolutionWidth <= value) || false;

  getLanguage = () => require(`antd/lib/locale-provider/${this.language}.js`).default;

  onUpdate = (registration: ServiceWorkerRegistration) => {
    this.waitingWorker = registration?.waiting;
    this.newVersionAvailable = true;
  };

  updateServiceWorker = () => {
    this.waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    this.waitingWorker?.addEventListener('statechange', (e: any) => {
      if (e.target?.state === 'activated') {
        this.newVersionAvailable = false;
        window.location.reload();
      }
    });
  };
}
const appStore = new AppStore();
export default appStore;
