import React from 'react';
import { Observer } from 'mobx-react';
import { Button, notification } from 'antd';
import appStore from '../../stores/app.store';
import { t } from '../../i18n/util';

notification.config({
  duration: 7
});

const UpdateSW = () => {
  const { updateServiceWorker } = appStore;
  const openNotification = () => {
    return notification.open({
      message: <div style={{ textAlign: 'center' }}>{`${t('msgNewVersionAvailable')}`}</div>,
      description: (
        <div style={{ textAlign: 'center' }}>
          <Button style={{ borderRadius: '35px' }} type="primary" onClick={updateServiceWorker}>
            {t('labelReload')}
          </Button>
        </div>
      )
    });
  };

  return <Observer render={() => <>{appStore.newVersionAvailable && openNotification()}</>} />;
};

export default UpdateSW;
