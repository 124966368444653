// third-party libraries
import React, {Suspense} from 'react';
import {RiftProvider, RiftGate} from 'rift-router';
// own libraries/files
import {routes} from './Routes';
import LoadingSplash from '../components/Splash/LoadingSplash';

const Router: React.FC = () => {
  return (
    <RiftProvider routes={routes}>
      <Suspense fallback={<LoadingSplash />}>
        <RiftGate /> {/* render the component for current route */}
      </Suspense>
    </RiftProvider>
  );
};

export default Router;
