import React from 'react';
import {AppContextType} from '../types/common.type';
import appStore from '../stores/app.store';

const getContextAppStores = (): AppContextType => {
  return {
    appStore,
  };
};
export const ContextStoresApp = React.createContext<AppContextType>(getContextAppStores());
