import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import app_es from './translations/es/app.json';
import app_en from './translations/en/app.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    app: app_en,
  },
  es: {
    app: app_es,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    ns: ['app'],
    lng: 'en-US',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
