import React from 'react';
import { IRiftRoute } from 'rift-router';
const Main = React.lazy(() => import('../../Main/Main'));
const Auth = React.lazy(() => import('../../Session/Auth'));
const FarmsContainer = React.lazy(() => import('../../Main/Farms/FarmsContainer'));
const MainViewFarmContainer = React.lazy(() => import('../../Main/Farms/MainVewFarm/MainViewFarmContainer'));
const AnimalsContainer = React.lazy(() => import('../../Main/Animals/AnimalsContainer'));
const AnimalProfileContainer = React.lazy(() => import('../../Main/Animals/Profile/AnimalProfileContainer'));
const MapContainer = React.lazy(() => import('../../Main/Map/MapContainer'));
const MainDrinkerDevicesContainer = React.lazy(() => import('../../Main/DrinkerDevices/MainDrinkerDevicesContainer'));
const MainAlertsContainer = React.lazy(() => import('../../Main/Alerts/MainAlertsContainer'));
const ConfigContainer = React.lazy(() => import('../../Main/Config/ConfigContainer'));
const UserContainer = React.lazy(() => import('../../Main/Config/User/UserContainer'));
const ConfigFarm = React.lazy(() => import('../../Main/Config/Farm/ConfigFarm'));
const CompanyContainer = React.lazy(() => import('../../Main/Config/Company/container/CompanyContainer'));
const PaddocksContainer = React.lazy(() => import('../../Main/Config/Paddocks/PaddocksContainer'));
const ReportsContainer = React.lazy(() => import('../../Main/Reports/ReportsContainer'));

const ENV = `${process.env.REACT_APP_ENV}`;
const STORE_VAR = `jwtMuucontrol-${ENV}`;

// @params: `user` is the user permissions
// and `permissions` is the permissions auth for functionality
// const hasPermissions = (user, permissions = []) => permissions.some(perm => user.includes(perm));
const isAuthorized = rolesAuth => {
  if (localStorage.getItem(STORE_VAR)) {
    const storage: any = localStorage.getItem(STORE_VAR);
    const data = JSON.parse(storage);
    return !!data;
  }
};

/**
 *
 * @param roles : ['OWNER']
 * @param pathToRedirect: /login
 */
const redirectTo = (roles, pathToRedirect) => {
  if (!isAuthorized(roles)) {
    localStorage.removeItem(STORE_VAR);
    return '/login';
  }
};

export const routes: IRiftRoute[] = [
  {
    path: '/main',
    component: <Main />,
    children: [
      {
        path: '/farm',
        component: <FarmsContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/farm/:id?',
        component: <MainViewFarmContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/farm/:type/:id?',
        component: <AnimalsContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/animal/:id?',
        component: <AnimalProfileContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/map/:id?',
        component: <MapContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/alerts/:id?',
        component: <MainAlertsContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/drinkerdevices/:id?',
        component: <MainDrinkerDevicesContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/report/:id?',
        component: <ReportsContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/config/:id?',
        component: <ConfigContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/config/users/:id?',
        component: <UserContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/config/farms/:id?',
        component: <ConfigFarm />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/config/companies/:id?',
        component: <CompanyContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      {
        path: '/config/paddocks/:id?',
        component: <PaddocksContainer />,
        onEnter: () => {
          return redirectTo(['OWNER'], '/farm');
        }
      },
      { path: '*', component: () => <h1>Not Found 404</h1> }
    ]
  },
  {
    path: '/login',
    component: <Auth />,
    onEnter: () => {
      if (isAuthorized(['OWNER'])) {
        return '/main/farm';
      }
    }
  },
  {
    path: '/',
    component: <h1>Main Path</h1>,
    onEnter: () => {
      if (isAuthorized(['OWNER'])) {
        return '/main/farm';
      } else {
        return '/login';
      }
    }
  },
  { path: '*', component: () => <h1>Not Found 404</h1> }
];
