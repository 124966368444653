// third-party libraries
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';

// own libraries/files
import authService from './auth.service';
import openNotificationWithIcon from '../components/OpenNotificationWithIcon/OpenNotificationWithIcon';

const API = `${process.env.REACT_APP_API}`;
const ENV = `${process.env.REACT_APP_ENV}`;
const STORE_VAR = `jwtMuucontrol-${ENV}`;

export class ApirestService {
  config: AxiosRequestConfig;
  constructor() {
    this.config = {
      baseURL: `${API}`,
      headers: {
        'Content-Type': 'application/json',
      },
      maxContentLength: 2000,
      maxRedirects: 5,
      responseType: 'json',
      timeout: 60000,
      validateStatus: (status: number) => status >= 200 && status < 300,
      withCredentials: false,
      xsrfCookieName: 'XSRF-TOKEN',
      xsrfHeaderName: 'X-XSRF-TOKEN',
    };
  }

  /**
   * @returns data Storage
   */
  async getSessionData() {
    const jwtData: any = await localStorage.getItem(STORE_VAR);
    if (jwtData) {
      return JSON.parse(jwtData);
    }
    return {token: '', roles: [], permissions: []};
  }

  createHttpAxios(url: string, cnf?: AxiosRequestConfig): AxiosInstance {
    // const resultConfig = Object.assign({}, this.config);
    const resultConfig = {...this.config, ...cnf};
    // resultConfig.baseURL += url;
    resultConfig.baseURL = `${resultConfig.baseURL}${url}`;
    const axiosInstance = axios.create(resultConfig);

    // axiosInstance.interceptors.request.use(
    //   (conf: any) => {
    //     if (!this.getSessionData() && !conf.baseURL.includes('/login')) {
    //       throw new axios.Cancel(HttpEnum.HTTP_CANCELED);
    //     }
    //     if (!conf.headers.Authorization) {
    //       conf.headers.Authorization = `Token ${authService.getSessionData().token}`;
    //     }

    //     return conf;
    //   },
    //   error => {
    //     return Promise.reject(error);
    //   }
    // );
    axiosInstance.interceptors.request.use((conf: any) => {
      conf.headers.Authorization = `Token ${authService.getSessionData().token}`;
      return conf;
    });
    axiosInstance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: any) => {
        if (error.response && error.response.status) {
          const status = error.response.status!;

          if (500 === status) {
            openNotificationWithIcon('error', 'Server Error', '');
            return;
          } else if (400 === status) {
            const data = error.response.data;
            if (data.hasOwnProperty('non_field_errors')) {
              openNotificationWithIcon('error', `${data.non_field_errors}`, '');
              return;
            }
          } else if (401 === status || 403 === status) {
            openNotificationWithIcon('error', `Session Expired`, '');
            authService.logout();
            window.location.href = '/login';
          }
        }
        return Promise.reject(error);
      },
    );

    return axiosInstance;
  }
}

const apirestService = new ApirestService();
export default apirestService;
