import React, { FC } from 'react';

import './LoadingSplash.less';
import { LogoSplash } from '../../../images/logos';

const LoadingSplash: FC = () => {
  return (
    <div className="loading-splash">
      <LogoSplash width={200} height={200} />
    </div>
  );
};

export default LoadingSplash;
