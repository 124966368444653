import { TFunction, TOptions } from 'i18next';

export const { t, initTranslation } = (() => {
  let a = {} as TFunction;
  return {
    t: (key: any, defaultValue?: string, options?: TOptions | string) => a(key, defaultValue, options),
    initTranslation: (operator: any) => {
      a = operator?.('app').t;
    }
  };
})();
