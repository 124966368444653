import React, { Suspense } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Observer } from 'mobx-react';
import { ConfigProvider, Spin } from 'antd';
import 'moment/locale/es';
import './App.less';
import i18n from './shared/i18n/i18n';
import Router from './shared/routes/Router';
import { ContextStoresApp } from './shared/Contexts/AppContext';
import { initTranslation } from './shared/i18n/util';
import UpdateSW from './shared/components/UpdateSW/UpdateSW';
import appStore from './shared/stores/app.store';

const App = () => {
  initTranslation(useTranslation);
  i18n.changeLanguage(appStore.getLanguage().locale).then();

  return (
    <div className="app-component">
      <Observer
        render={() => (
          <ContextStoresApp.Provider value={{ appStore }}>
            <Suspense fallback={<Spin size="large" spinning={appStore?.isLoading} />}>
              <I18nextProvider i18n={i18n}>
                <ConfigProvider locale={appStore.getLanguage()}>
                  <UpdateSW />
                  <Router />
                </ConfigProvider>
              </I18nextProvider>
            </Suspense>
          </ContextStoresApp.Provider>
        )}
      />
    </div>
  );
};

export default App;
